import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { renderText } from '../../helpers/api';
import NoMatch  from '../NoMatch/NoMatch';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';

@inject('pagesStore', 'navigationStore')
@observer
class LoadPage extends Component {

  slug = '';
  store = {};

  constructor(props) {
    super(props);
    this.getCurrentLang();
    this.store = this.props.store ? this.props.store : this.props.pagesStore;
    this.slug = this.props.slug;
  }
  
  getCurrentLang = () => {
    this.lang = this.props.lang
  }

  componentDidMount() {
    this.props.pagesStore.fetchIfNeeded(this.slug, this.lang);
    if (this.props.pagesStore.isFetched.get(this.slug)) {
      this.props.navigationStore.setActivePostLangs({});
    }
  }

  componentDidUpdate() {
    this.getCurrentLang();
    this.props.pagesStore.fetchIfNeeded(this.slug, this.lang);
    if (this.props.pagesStore.isFetched.get(this.slug)) {
        this.props.navigationStore.setActivePostLangs({});
    }
  }
      
  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    const isFetched = this.props.pagesStore.isFetched.get(this.slug);
    const post = this.props.pagesStore.data.posts[this.slug];
    if (isFetched && false === post) {
      return <NoMatch location={this.props.location} />;
    }

    return (
      <div className="mink-page">
        { isFetched ? (
          <div className="mink-content">
            {/* {this.renderHead()} */}
            <div className="ez-title d-lg-none">{post.title}</div>
            <div className="row">
              <div className="col-12 -post-content mt-5">
                {renderText(post.content)}
              </div>
            </div>

          </div>
        ) : (
          <LoadingAnimation/>
        )}
      </div>
    );
  }
}

export default LoadPage;
