/**
  Helper functions for communication with REST API
 */
import React from 'react';
import Helmet from 'react-helmet';
import Parser from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import { Link } from 'react-router-dom';
import { UncontrolledCollapse } from 'reactstrap';
import ContentSlider from '../components/posts/ContentSlider/ContentSlider';


const getHomeUrl = () => {
  if (typeof process.env.REACT_APP_REST_API_URL !== 'undefined') {
    return process.env.REACT_APP_REST_API_URL;
  }
  if ( typeof window !== 'undefined' && typeof window.location !== 'undefined' && window.location.hostname !== 'localhost' && !/192\.168\./.test(window.location.hostname)) {
    return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port: '/');
  }
  if (typeof process.env.REACT_APP_FALLBACK_REST_API_URL !== 'undefined') {
    return process.env.REACT_APP_FALLBACK_REST_API_URL;
  }
  return '/'
};


const getEndpointUrl = () => {
  return getHomeUrl() + 'wp-json/';
}

const getApiconfig = () => {
  const config = {
    endpoint: getEndpointUrl()
  };
  if ( typeof window !== 'undefined' && typeof window.MINK !== 'undefined' && window.MINK.nonce ) {
    config.nonce = window.MINK.nonce;
  }

  return config;
}

const getSiteName = () => {
  let siteName = '';
  if ( typeof window !== 'undefined' && typeof window.MINK !== 'undefined' && window.MINK.title ) {
    siteName = window.MINK.title;
  }
  return siteName;
}

const getCollapseThreshold = () => {
  let threshold = 5;
  if ( typeof window !== 'undefined' && typeof window.MINK !== 'undefined' && window.MINK.collapse_threshold ) {
    threshold = window.MINK.collapse_threshold;
  }
  return threshold;
}

/**
 *
 * @param wpApi wpapi.WPAPI
 */
const setHeaders = (wpApi) => {
  if ('production' === process.env.NODE_ENV) {
    wpApi.setHeaders({
      'Cache-Control': 'public, max-age=' + ('undefined' !== typeof process.env.REACT_APP_CACHE_MAX_AGE ? process.env.REACT_APP_CACHE_MAX_AGE : 14400),
      'Pragma': 'public',
    });
  }
}

const createMarkup = (text) => {
  return {__html: text};
}

const renderTitle = (text, withSiteName) => {
  withSiteName = withSiteName !== false;
  if (text && text.rendered) {
    text = text.rendered;
  }
  if (withSiteName) {
    text = text + ' - ' + getSiteName();
  }
  if (text) {
    return  <Helmet><title>{Parser(text)}</title></Helmet>;
  } else {
    return null;
  }
}

const renderStyles = (style) => {
  return <Helmet><style>{style}</style></Helmet>
}

const toggleCollapse = (e) => {
  e.currentTarget.classList.toggle('open');
  e.currentTarget.classList.toggle('closed');
}

const replaceCallback = (domNode) => {
  if (
    domNode.name === 'a' &&
    domNode.attribs &&
    domNode.attribs.href
  ) {
    const href = domNode.attribs.href;
    const isRouterLink = !/^http|^\/\/|^\/content|^#|^mailto|^tel:/.test(href);

    if (isRouterLink) {
      const attr = attributesToProps(domNode.attribs);
      const {href, ...props } = attr;
      return <Link to={href} {...props}>{domToReact(domNode.children)}</Link>
    }

  }  else if (
    domNode.name === 'div' &&
    domNode.attribs &&
    domNode.attribs.class &&
    domNode.attribs.id &&
    (
      domNode.attribs.class.split(" ").indexOf('mink-collapse') !== -1
    ) &&
    domNode.children
  ) {
    const children = [];
    for (let i=0; i < domNode.children.length; i++) {
      if (domNode.children[i].name === 'div') {
        const key = domNode.attribs.id + i;
        // const childNodes = domNode.children[i].children.filter(node => node.type === 'tag');
        const childNodes = domNode.children[i].children.filter(node => node.name === 'span');
        const collapse = (
          <div key={key} className={'mink-collapse__item'}>
              <div className={childNodes[2] && childNodes[2].children[0].data ? 'mink-collapse__toggler open' : 'mink-collapse__toggler closed'} color="primary" id={key + 'toggler'} onClick={toggleCollapse}>              
                <div className="flex-shrink-1 -toggler-icon"><span></span></div>
                <h2 className="w-100">{domToReact(childNodes[0].children)}</h2>
              </div>
              <UncontrolledCollapse className={childNodes[2] && 'open' === childNodes[2].children[0].data ? 'show' : ''} toggler={key + 'toggler'}>
                <div className="mink-collapse__content ">
                  {domToReact(childNodes[1].children)}
                </div>
              </UncontrolledCollapse>
          </div>
        );
        children.push(collapse);
      }
    }
    return (
      <div className={domNode.attribs.class} id={domNode.attribs.id}>
        <div className="row">
          <div className="col-12">
            {children}
          </div>
        </div>

      </div>
    );
  // look for content slider .mink-content-slider
  } else if(
    domNode.name === 'div' &&
    domNode.attribs &&
    domNode.attribs.class &&
    domNode.attribs.class.split(" ").indexOf('mink-content-slider') !== -1) {
      const childNodes = domNode.children.filter(node => node.name === 'div');
      return (
        <ContentSlider attribs={attributesToProps(domNode.attribs)}>
          {domToReact(childNodes, parserOptions)}
        </ContentSlider>
      );
  }
  
};

const parserOptions = {
  replace: replaceCallback
}

const renderText = (text) => {
  if (text) {
    if (text.rendered) {
      text = text.rendered;
    }
    return  Parser(text, parserOptions);
  } else {
    return null;
  }
}

const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {}
};

const parseUrlComponents = url => {
  const parser = document.createElement("a");
  parser.href = url;
  return {
    protocol: parser.protocol,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    hash: parser.hash,
    host: parser.host,
  }
}

const getLocation = () => {
  if(typeof window !== 'undefined' && typeof window.location !== 'undefined') {
    return window.location;
  }

}

export {
  getHomeUrl,
  getEndpointUrl,
  getApiconfig,
  getSiteName,
  setHeaders,
  createMarkup,
  renderTitle,
  renderText,
  renderStyles,
  parserOptions,
  getQueryStringParams,
  parseUrlComponents,
  getLocation,
  getCollapseThreshold
};
