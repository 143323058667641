import { action, observable, computed } from 'mobx';
import { getCurrentLang, getDefaultLang } from '../../helpers/lang';

export default class NavigationStore {

  /**
   * holds the routerStore
   */
  routerStore = {}

  /**
   * // To stop listening, call the function returned from listen().
   * unlisten();
   */
  unlisten = null;
  primaryNavigationItems = [];
  /**
   * holds current pathname
   */
  pathname = '';
  @observable navIsActive = false;
  @observable colIsActive = false;
  @observable mapIsActive = false;
  @observable activePostLangs = {};

  constructor(routerStore) {

    if ( typeof window !== 'undefined' && typeof window.MINK !== 'undefined' && window.MINK.primary_navigation ) {
      this.primaryNavigationItems = window.MINK.primary_navigation;
      this.secondaryNavigationItems = window.MINK.secondary_navigation;
      this.conceptsNavigationItems = window.MINK.concepts_navigation;
      this.footerNavigationItems = window.MINK.footer_navigation;
      this.postTypesRoutes = window.MINK.routes;
      this.activeLanguges = window.MINK.al;
    }
    this.routerStore = routerStore;
    this.pathname = routerStore.location.pathname;
    this.currentLanguage = getCurrentLang();
    // listen to history
    this.unlisten = routerStore.history.listen((location, action) => {
      if (this.pathname !== location.pathname) {
        this.pathname = location.pathname;
        // this.closeNav();
      }
    });
  }

  handleClick = (e, item) => {
    if ('#open-map' === item.url ){
      this.toggleMap(true);
      e.preventDefault();
      return false;
    }


    const urlComponents = item.url_components;

    // use routerStore only if link is internal
    const homeUrl = this.postTypesRoutes._meta.home_url;
    const isExtern = undefined !== urlComponents.scheme && 
      (
        !/http/i.test(urlComponents.scheme) || 
        (urlComponents.host && !new RegExp(urlComponents.host, 'i').test(homeUrl))
      );
    if (!isExtern) {
      let path;
      if (!typeof urlComponents === 'object' || !urlComponents.hasOwnProperty('path')) {
        path = item.url;
      } else {
        path = urlComponents.path;
        if (urlComponents.hasOwnProperty('query')) {
          path = path + '?' + urlComponents.query
        }
        if (urlComponents.hasOwnProperty('fragment')) {
          path = path + '#' + urlComponents.fragment
        }
      }

      e.preventDefault();
      this.routerStore.push(path);
      // this.closeNav();
      return false;
    }
  }

  isCurrentMenuItem = (urlComponents) => {
    const cp = this.routerStore.location.pathname;
    if (typeof urlComponents === 'object' && urlComponents.hasOwnProperty('path')) {
      return (cp === urlComponents.path)
    }
    return  false;
  };

  @action
  toggleNav = () => {
    this.navIsActive = !this.navIsActive;
    this.navIsActive ? document.body.classList.add('show-nav') : document.body.classList.remove('show-nav');
  };

  @action
  toggleCols = (toggler) => {
    document.body.classList.remove('show-future');
    document.body.classList.remove('show-past');
    document.body.classList.remove('show-nav');
    this.colIsActive = false;
    if('showpast' === toggler) {
      document.body.classList.add('show-past');
      this.navIsActive = false;
      this.colIsActive = true;
    }
    if('showfuture' === toggler) {
      document.body.classList.add('show-future');
      this.navIsActive = false;
      this.colIsActive = true;
    }
  }


  /**
   * on location change / navigate close the menu
   */
  @action
  closeNav = () => {
    this.navIsActive = false;
    document.body.classList.remove('overflow');
  };

  @action
  toggleMap = (open) => {
    if (false === open || true === open) {
      this.mapIsActive = open;
    } else {
      this.mapIsActive = !this.mapIsActive;
    }
    // this.mapIsActive = !this.mapIsActive;
    this.mapIsActive ? document.body.classList.add('map') : document.body.classList.remove('map');
  };

  @action 
  setActivePostLangs( translations ) {
    this.activePostLangs = translations;
  }

  @computed get langSwitchData() {
    const data = {};
    const cl = getCurrentLang();
    const dl = getDefaultLang();
    for ( let lang in this.activeLanguges ) {
      if ( cl !== lang ) {
        if (this.activePostLangs && this.activePostLangs[lang]) {
          const path = '/' + (lang !== dl ? lang + '/' : '') + this.activePostLangs[lang].slug;
          data[lang] = {
            tag: this.activePostLangs[lang].tag,
            path: path
          }
        } else {
          const path = '/' + (lang !== dl ? lang + '/' : '');
          data[lang] = {
            tag: this.activeLanguges[lang].tag,
            path: path
          }
        }
      }
    }
    return data;
  }


}
