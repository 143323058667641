import React, { Component } from 'react';
// import VisibiltySensor from 'react-visibility-sensor';
import FooterNav from '../../containers/FooterNav/FooterNav';
import { inject, observer } from 'mobx-react';
import { renderText } from '../../helpers/api';

@inject('sidebarsStore', 'navigationStore', 'scrollStore')
@observer
class Footer extends Component {

  sidebar = [];

  constructor(props) {
    super(props);
    this.sidebar = props.sidebarsStore;
  }

  componentDidMount() {
    this.props.sidebarsStore.fetchIfNeeded('sidebar-footer');
  }

  componentDidUpdate() {
    this.props.sidebarsStore.fetchIfNeeded('sidebar-footer');
  }

  render() {
    const isFetched = this.props.sidebarsStore.isFetched.get('sidebar-footer');
    // console.log(this.sidebar);

    return (
      <div>
        <footer className="mink-footer">

          <div className="mink-footer__content">
            <div className="row">
              <div className="col-12 d-flex">
                <div className="-copy">
                { isFetched ? (
                  renderText(this.sidebar.data.posts['sidebar-footer'])
                ) : ( null )}
                </div>
                <FooterNav/>
              </div>
            </div>
          </div>
        </footer>
      </div>

    );
  }
}
export default Footer;
