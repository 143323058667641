import React, { Component } from 'react';

class HomePageTeaser extends Component {

  render() {
    const teasers = this.props.teasers;
    const teaserList = teasers.map((teaser, i) => {
      return (
        <div className={i === this.props.show ? 'hp-teasers d-block' : 'hp-teasers'} key={i}>
          <div className="hp-teasers__hd">{teaser.hp_teaser_hd}</div>
          <div className="hp-teasers__txt">{teaser.hp_teaser_txt}</div>
        </div>
      )

    });
    return (
      <>
        {teaserList}
      </>
    );

  }
}

export default HomePageTeaser;
