import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { renderText } from '../../helpers/api';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import { getDefaultLang } from '../../helpers/lang';
import { getRandomInt } from '../../helpers/math';
import LoadPage from '../LoadPage/LoadPage';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';


@inject('pagesStore', 'navigationStore', 'sidebarsStore')
@observer
class NoMatch extends Component {

  slug = '';
  store = {};
  showQuote = 0;

  constructor(props) {
    super(props);
    this.getCurrentLang();
    this.sidebar = props.sidebarsStore;
    this.store = this.props.store ? this.props.store : this.props.pagesStore;
    this.state = {
      collapse: false,
      showpast: false,
      showfuture: false,
      showquotes: true
    };
    this.loadQuotes = false;
    this.homepage = {};
    this.showQuote = getRandomInt(7) +1;
  }

  getCurrentLang = () => {
    if (this.props.match && this.props.match.params) {
      const lang = this.props.match.params.lang;
      this.lang = lang ? lang : getDefaultLang();
    }
  }

  componentDidMount() {
    this.props.sidebarsStore.fetchIfNeeded('sidebar-404');
    if (this.props.apiParams && this.props.apiParams.postType) {
      this.store.fetchPathIfNeeded(this.slug);
    } else {
      this.store.fetchIfNeeded(this.slug, this.lang);
    }

    document.body.classList.add('page');
    if (this.store.isFetched.get(this.slug)) {
      const postType = this.store.data.posts[this.slug].post_type;
      document.body.classList.add('single-' + postType);
      this.props.navigationStore.setActivePostLangs(this.store.data.posts[this.slug].translations);
    }
  }
  componentWillUnmount() {
    document.body.classList.remove('page');
    if (this.store.isFetched.get(this.slug)) {
      const postType = this.store.data.posts[this.slug].post_type;
      document.body.classList.remove(postType, 'single-' + postType);
    }

  }

  componentDidUpdate() {
    this.props.sidebarsStore.fetchIfNeeded('sidebar-404');
    this.getCurrentLang();
    if (this.props.apiParams && this.props.apiParams.postType) {
      this.store.fetchPathIfNeeded(this.slug);
    } else {
      this.store.fetchIfNeeded(this.slug, this.lang);
    }

    if (this.store.isFetched.get(this.slug)) {
      const postType = this.store.data.posts[this.slug].post_type;
      document.body.classList.add('single-' + postType);
      this.props.navigationStore.setActivePostLangs(this.store.data.posts[this.slug].translations);
    }
  }

  toggle = (toggler) => {
    let togglerStatus = this.state[toggler];
    this.setState({
      showpast: false,
      showfuture: false,
      showquotes: false,
      [toggler]: !togglerStatus
    });
  }

  render() {
    const toggleCols = this.props.navigationStore.toggleCols;
    const isFetchedSidebar = this.props.sidebarsStore.isFetched.get('sidebar-404');

    return (
      <div>
        <Header/>
        <div className={'ez-future-wrapper'} onClick={() => toggleCols('showfuture')}><LoadPage slug="future" lang={this.lang}/></div>
        <div className="ez-container container" onClick={() => toggleCols('clear')}>
          <main className="mink-main">
            <div className="mink-page -homepage">
              { isFetchedSidebar ? (
                <div>
                  
                  <div className="mink-content">
                    <div className="row">
                      <div className="col-12 -post-content mt-5">
                      {renderText(this.sidebar.data.posts['sidebar-404'])}
                      </div>
                    </div>

                  </div>
                  
                </div>
              ) : (
                <LoadingAnimation/>
              )}
            </div>
          </main>
          <Footer/>
        </div>
        <div className={'ez-past-wrapper'} onClick={() => toggleCols('showpast')}><LoadPage slug="past" lang={this.lang}/></div>
      </div>
      
    );
  }
}

export default NoMatch;

