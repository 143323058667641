import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle, renderText } from '../../helpers/api';
import NoMatch  from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import { getDefaultLang } from '../../helpers/lang';
import { getRandomInt } from '../../helpers/math';
import LoadPage from '../LoadPage/LoadPage';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import HomePageTeaser from './HomePageTeaser';

@inject('pagesStore', 'navigationStore')
@observer
class HomePage extends Component {

  slug = 'homepage';
  showQuote = 0;

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      showpast: false,
      showfuture: false,
      showquotes: true
    };
    this.getCurrentLang();
    this.showQuote = getRandomInt(7) +1;
  }

  renderHead() {
    if (this.props.pagesStore.isFetched.get(this.slug) && this.props.pagesStore.data.posts[this.slug].location && this.props.pagesStore.data.posts[this.slug].location.title) {
      return renderTitle(this.props.pagesStore.data.posts[this.slug].location.title, false);
    } else {
      return null;
    }
  }

  getCurrentLang = () => {
    if (this.props.match && this.props.match.params) {
      const lang = this.props.match.params.lang;
      this.lang = lang ? lang : getDefaultLang();
    }
    if ('de' === this.lang) {
      this.slug = 'homepage';
    }
  }

  componentDidMount() {
    this.props.pagesStore.fetchIfNeeded(this.slug, this.lang);
    document.body.classList.add('page');
    if (this.props.pagesStore.isFetched.get(this.slug)) {
      this.props.navigationStore.setActivePostLangs({});
    }
  }
  componentWillUnmount() {
    document.body.classList.remove('page');
  }

  componentDidUpdate() {
    this.getCurrentLang();
    this.props.pagesStore.fetchIfNeeded(this.slug, this.lang);
    if (this.props.pagesStore.isFetched.get(this.slug)) {
      this.props.navigationStore.setActivePostLangs({});
    }
  }

  toggle = (toggler) => {
    let togglerStatus = this.state[toggler];
    this.setState({
      showpast: false,
      showfuture: false,
      showquotes: false,
      [toggler]: !togglerStatus
    });
  }

  render() {
    const isFetched = this.props.pagesStore.isFetched.get(this.slug);
    const post = this.props.pagesStore.data.posts[this.slug];
    const toggleCols = this.props.navigationStore.toggleCols;
    const scrollToTop = !this.props.location.hash && (!this.props.location.state || !this.props.location.state.scrollTo);
    if (isFetched && false === post) {
      return <NoMatch location={this.props.location} />;
    }

    if (isFetched) {
      this.showQuote = getRandomInt(post.fields.field_hp_teasers.length) +1;
    }

    return (
      <div>
        {/* <div className={this.state['showpast'] ? 'show-past' : '' || this.state['showfuture'] ? 'show-future' : ''}> */}
        <Header/>
        <div className={'ez-future-wrapper'} onClick={() => toggleCols('showfuture')}><LoadPage slug="future" lang={this.lang}/></div>
        <div className="ez-container container" onClick={() => toggleCols('clear')}>
          <main className={'mink-main'}>
            <div className={this.state['showquotes'] ? 'hp-teaser__bg' : 'hp-teaser__bg' }></div>
            <div className="mink-page -homepage">
              { isFetched ? (
                  
                <div className="mink-content">
                  {scrollToTop && <ScrollToTop onHistoryPush={true}/>}
                  <ScrollToHash/>
                  {this.renderHead()}
                  <div className="row">
                    <div className="col-12 -post-content mt-5">
                      {renderText(post.content)}
                    </div>
                  </div>
                </div>
                  
              ) : (
                <LoadingAnimation/>
              )}
            </div>
          </main>
          <Footer/>
        </div>
        <div className={'ez-past-wrapper'} onClick={() => toggleCols('showpast')}><LoadPage slug="past" lang={this.lang}/></div>
        { isFetched ? (
          <div className={this.state['showquotes'] ? 'hp-teaser__content' : 'hp-teaser__content' }>
            <div className={this.state['showquotes'] ? 'hp-teaser__close' : 'hp-teaser__close closed' } onClick={() => this.toggle('showquotes')}></div>
            <div className={this.state['showquotes'] ? '' : 'd-none' }>
              {post.fields && post.fields.field_hp_teasers && <HomePageTeaser show={this.showQuote} teasers={post.fields.field_hp_teasers}/>}
            </div>
          </div>
          ) : (
            <LoadingAnimation/>
          )}
      </div>
    );
  }
}

export default HomePage;
