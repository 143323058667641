import React, { Component } from 'react';
import SubMenu from '../../components/nav/SubMenu/SubMenu';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import LangSwitch from '../../components/nav/LangSwitch/LangSwitch';

@inject('navigationStore', 'routerStore')
@observer
class Nav extends Component {

  menuItems = [];

  constructor(props) {
    super(props);
    this.menuItems = props.navigationStore.primaryNavigationItems;
  }

  render () {
    const currentPath = this.props.routerStore.location.pathname;
    const isCurrentMenuItem = this.props.navigationStore.isCurrentMenuItem;
    const handleClick = this.props.navigationStore.handleClick;
    const toggleNav = this.props.navigationStore.toggleNav;
    const toggleCols = this.props.navigationStore.toggleCols;

    return (
      <div>
        <nav className="mink-main-nav">
          <div className="ez-future-nav" onClick={(this.props.navigationStore.colIsActive ? () => toggleCols('clear') : () => toggleCols('showfuture'))}>Future</div>
          <div className="ez-present-nav" onClick={() => toggleCols('clear')}>
            <div className="d-flex">
              <div className="d-lg-flex w-100 ">
                <div className="mink-main-nav__toggler" onClick={toggleNav}>
                  Present <span className={'navbar-toggler-icon' + (this.props.navigationStore.navIsActive ? ' is_active' : '')}></span>
                </div>
                <div className={'mink-main-nav__container' + (this.props.navigationStore.navIsActive ? ' -show' : ' -hide')}>
                  <div className="mink-main-nav__menu">
                    <SubMenu items={this.menuItems} depthLevel={0} isCurrentMenuItem={isCurrentMenuItem} handleClick={handleClick} currentPath={currentPath}/>
                  </div>
                  
                </div>
              </div>
              <div className="flex-shrink-1">
                <LangSwitch/>
              </div>                
            </div>
          </div>
          <div className="ez-past-nav" onClick={(this.props.navigationStore.colIsActive ? () => toggleCols('clear') : () => toggleCols('showpast'))}>Past</div>

        </nav>


        <h1 className="mink-header__brand"><Link to='/'>Europas Zukunft</Link></h1>


      </div>

    );
  }
}

export default Nav
