import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle, renderText } from '../../helpers/api';
import NoMatch  from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import { getDefaultLang } from '../../helpers/lang';
import { getRandomInt } from '../../helpers/math';
import LoadPage from '../LoadPage/LoadPage';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import HomePageTeaser from '../HomePage/HomePageTeaser';

@inject('pagesStore', 'navigationStore')
@observer
class Single extends Component {

  slug = '';
  store = {};
  showQuote = 0;

  constructor(props) {
    super(props);
    this.getCurrentSlug();
    this.getCurrentLang();
    this.store = this.props.store ? this.props.store : this.props.pagesStore;
    this.state = {
      collapse: false,
      showpast: false,
      showfuture: false,
      showquotes: true
    };
    this.loadQuotes = false;
    this.homepage = {};
    this.showQuote = getRandomInt(7) +1;
  }

  renderHead() {
    if (this.store.isFetched.get(this.slug) && this.store.data.posts[this.slug].title) {
      return renderTitle(this.store.data.posts[this.slug].title);
    } else {
      return null;
    }
  }

  getCurrentSlug = () => {
    if (this.props.match && this.props.match.params) {
      if (this.props.apiParams && this.props.apiParams.postType) {
        this.slug = this.props.location.pathname;
      } else {
        const slug = this.props.match.params.id;
        this.slug = slug ? slug : 'homepage';
      }
    }
  }
  getCurrentLang = () => {
    if (this.props.match && this.props.match.params) {
      const lang = this.props.match.params.lang;
      this.lang = lang ? lang : getDefaultLang();
    }
  }

  componentDidMount() {
    if (this.props.apiParams && this.props.apiParams.postType) {
      this.store.fetchPathIfNeeded(this.slug);
    } else {
      this.store.fetchIfNeeded(this.slug, this.lang);
    }

    document.body.classList.add('page');
    if (this.store.isFetched.get(this.slug)) {
      const postType = this.store.data.posts[this.slug].post_type;
      document.body.classList.add('single-' + postType);
      this.props.navigationStore.setActivePostLangs(this.store.data.posts[this.slug].translations);
    }
  }
  componentWillUnmount() {
    document.body.classList.remove('page');
    if (this.store.isFetched.get(this.slug)) {
      const postType = this.store.data.posts[this.slug].post_type;
      document.body.classList.remove(postType, 'single-' + postType);
    }

  }

  componentDidUpdate() {
    this.getCurrentSlug();
    this.getCurrentLang();
    if (this.props.apiParams && this.props.apiParams.postType) {
      this.store.fetchPathIfNeeded(this.slug);
    } else {
      this.store.fetchIfNeeded(this.slug, this.lang);
    }

    if (this.store.isFetched.get(this.slug)) {
      const postType = this.store.data.posts[this.slug].post_type;
      document.body.classList.add('single-' + postType);
      this.props.navigationStore.setActivePostLangs(this.store.data.posts[this.slug].translations);
    }
  }

  toggle = (toggler) => {
    let togglerStatus = this.state[toggler];
    this.setState({
      showpast: false,
      showfuture: false,
      showquotes: false,
      [toggler]: !togglerStatus
    });
  }

  render() {
    const isFetched = this.store.isFetched.get(this.slug);
    const post = this.store.data.posts[this.slug];
    const toggleCols = this.props.navigationStore.toggleCols;
    const scrollToTop = !this.props.location.hash && (!this.props.location.state || !this.props.location.state.scrollTo);
    let isFetchedQuotes = false;

    if ((isFetched && false === post) || (this.store.isFetchedAll && !post)) {
      return <NoMatch/>;
    }

    if ((isFetched && post['terms'].category)) {      
      this.props.pagesStore.fetchIfNeeded('homepage', this.lang);
      isFetchedQuotes = this.props.pagesStore.isFetched.get('homepage');
      if(isFetchedQuotes) {
        this.homepage = this.props.pagesStore.data.posts['homepage'];
        this.showQuote = getRandomInt(this.homepage.fields.field_hp_teasers.length) +1;        
      }
      const terms = post['terms'].category;
      this.loadQuotes = terms.map((cats, i) => {
        if ('quotes' !== cats.name ) {
          this.loadQuotes = false;
        } else {
          this.loadQuotes = true;
        }
        return this.loadQuotes;
      });
    }


    return (
      <div>
        <Header/>
        <div className={'ez-future-wrapper'} onClick={() => toggleCols('showfuture')}><LoadPage slug="future" lang={this.lang}/></div>
        <div className="ez-container container" onClick={() => toggleCols('clear')}>
          <main className="mink-main">
            { isFetchedQuotes && this.loadQuotes ? (
              <div className={this.state['showquotes'] ? 'hp-teaser__bg' : 'hp-teaser__bg' }></div>
            ):(
              <div></div>
            )};
            <div className="mink-page -homepage">
              { isFetched ? (
                <div>
                  
                  <div className="mink-content">
                    {scrollToTop && <ScrollToTop onHistoryPush={true}/>}
                    <ScrollToHash/>
                    {this.renderHead()}
                    <div className="row">
                      <div className="col-12 -post-content mt-5">
                        {renderText(post.content)}
                      </div>
                    </div>

                  </div>
                  
                </div>
              ) : (
                <LoadingAnimation/>
              )}
            </div>
          </main>
          <Footer/>
        </div>
        <div className={'ez-past-wrapper'} onClick={() => toggleCols('showpast')}><LoadPage slug="past" lang={this.lang}/></div>
        { this.loadQuotes ? (
          isFetchedQuotes ? (
          <div className={this.state['showquotes'] ? 'hp-teaser__content' : 'hp-teaser__content' }>
            <div className={this.state['showquotes'] ? 'hp-teaser__close' : 'hp-teaser__close closed' } onClick={() => this.toggle('showquotes')}></div>
            <div className={this.state['showquotes'] ? '' : 'd-none' }>
              {this.homepage.fields && this.homepage.fields.field_hp_teasers && <HomePageTeaser show={this.showQuote} teasers={this.homepage.fields.field_hp_teasers}/>}
            </div>
          </div>
          ) : (
            <LoadingAnimation/>
          )
          ):(
            <></>
          )}
      </div>
      
    );
  }
}

export default Single;
